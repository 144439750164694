import {SedestralMemory} from "./SedestralMemory";
import {isLocalStorageAvailable} from "../utilities/IsLocalStorageAvailable";

export class SedestralStorage {
    private static isLocalStorageEnabled = true;

    public static async init(): Promise<void> {
        if (!isLocalStorageAvailable()) {
            this.isLocalStorageEnabled = false;
        }
    }

    public static setItem(key: string, value: string): void {
        if (this.isLocalStorageEnabled) {
            localStorage.setItem(key, value);
        } else {
            SedestralMemory.setItem(key, value);
        }
    }

    public static getItem(key: string): string | null {
        if (this.isLocalStorageEnabled) {
            return localStorage.getItem(key);
        } else {
            return SedestralMemory.getItem(key);
        }
    }

    public static removeItem(key: string): void {
        if (this.isLocalStorageEnabled) {
            localStorage.removeItem(key);
        } else {
            SedestralMemory.removeItem(key);
        }
    }

    public static setCookie(name: string, value: string, domain: string = location.hostname): void {
        try {
            let expireDate = new Date();
            expireDate.setFullYear(expireDate.getFullYear() + 1); // Expire après le nombre d'années spécifié
            document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expireDate.toUTCString()}; domain=${domain}; path=/`;
        } catch (e) {
            console.error("Failed to set cookie", e);
        }
    }

    public static getCookie(name: string): string | null {
        const nameEQ = encodeURIComponent(name) + "=";
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];

            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }

            if (cookie.indexOf(nameEQ) === 0) {
                return decodeURIComponent(cookie.substring(nameEQ.length, cookie.length));
            }
        }

        return null;
    }

    public static deleteCookie(name: string): void {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
    }
}