@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentAccountBoard {
  height: 100%;
  width: 100%;

  .head {
    width: calc(100% - 30px);
    padding: 10px 15px 10px 15px;
    border-bottom: var(--grey-sur) 1px solid;

    .headTypes {
      @include positioning.clear;

      .account {
        height: 26px;
        width: 195px;
        padding: 5px;
        left: -5px;
        border-radius: 10px;
        font-size: 15px;
        float: left;
        transition: 0.1s;
        position: relative;
        cursor: pointer;

        @include effects.hover-alpha;
        @include texts.font("semi-bold");
        @include positioning.flex-vertically;

        .leftSide {
          .avatarContainer {
            height: 20px;
            width: 20px;
            margin-right: 10px;
            float: left;
            position: relative;
          }

          .name {
            float: left;
          }
        }
      }

      .activity {
        width: 95px;
        font-size: 15px;
        position: relative;
        float: right;
        border-left: 1px solid var(--grey-sur);
        padding-left: 8px;
        margin-top: 8px;

        @include positioning.clear;
        @include texts.font("semi-bold");

        .text {
          margin-top: 2px;
          float: left;
          font-size: 13px;
        }

        .switchContainer {
          float: right;
        }

      }
    }
  }


  .buttons {
    margin-top: 12px;

    .button {
      padding: 5px 5px 5px 0;
      margin-bottom: 2px;
      width: calc(100% - 26px);
      border-radius: 10px;
      font-size: 15px;
      left: 10px;
      transition: 0.1s;
      min-height: 38px;
      cursor: pointer;
      position: relative;

      @include positioning.clear;
      @include effects.hover-alpha;

      &.settings {
        .icon {
          @include icons.ico("3d-settings");
        }
      }

      &.help {
        .icon {
          @include icons.ico("3d-help");
        }
      }

      &.roles {
        .icon {
          @include icons.ico("3d-roles");
        }
      }

      &.channels {
        .icon {
          @include icons.ico("3d-channels");
        }
      }

      &.members {
        .icon {
          @include icons.ico("3d-members");
        }
      }

      &.logout {
        .icon {
          @include icons.ico("3d-disconnect");
        }
      }

      &.site {
        visibility: visible;
      }

      span {
        margin-top: 10px;
        display: block;
        margin-left: 10px;

        @include texts.font("semi-bold");
      }

      .iconContainer {
        height: 38px;
        width: 38px;
        position: relative;
        border-radius: 30px;
        float: left;
        @include positioning.flex-globally;


        .avatar {
          height: 24px;
          width: 24px;
          position: relative
        }

        .icon {
          height: 24px;
          width: 24px;
        }
      }

      .text {
        margin-left: 10px;
        margin-top: 9px;
        float: left;

        @include texts.font("semi-bold");
      }

      .arrow {
        height: 14px;
        width: 14px;
        margin-top: 12px;
        float: right;

        @include icons.ico("black-right");
      }
    }

    .language {
      height: 32px;
      position: relative;
      display: flex;
      padding: 5px 15px;
      align-items: center;
      gap: 15px;

      @include texts.font("semi-bold");

      .picker{
        width: 100%;
      }
    }
  }


  .build {
    font-size: 12px;
    color: var(--grey-text) !important;
    margin-left: 15px;
    margin-top: 8px;

    a {
      color: var(--grey-text) !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}