import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./modal.scss";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";

export class VisualModalComponent extends Component {
    public modalTemplate: string;
    public modalStyle: string;
    public name: string;
    public headMargin: boolean;

    public headContainer: Component;
    public contentContainer: Component;
    public nameContainer: Component;
    public boxContainer: Component;
    public visualModal: Component;

    public closeButton: Component;

    public fixed: boolean;

    constructor() {
        super();
        this.modalTemplate = `<div>template</div>`;
        this.modalStyle = "width:360px;"
        this.name = "name";

        this.template = `
            <div class="${s.visualModal}">
                <div class="${s.box}">
                    <div class="${s.modalHead}">
                        <div class="${s.name}"></div>
                        <div class="${s.CloseButton} ${s.round}">
                            <div class="${s.icon}"></div>
                        </div>
                    </div>
                    <div class="${s.modalContent}"></div>
                </div>
            </div>
        `;
    }

    commit(): void {
        this.visualModal = this.el(s.visualModal);
        this.headContainer = this.el(s.modalHead);
        this.boxContainer = this.el(s.box);
        this.nameContainer = this.el(s.name);
        this.contentContainer = this.el(s.modalContent);
        this.closeButton = this.el(s.CloseButton);

        this.nameContainer.setHtml(this.name);
        this.contentContainer.append(this.modalTemplate);

        this.boxContainer.onOutsideClick(() => {
            if (!this.fixed)
                this.close();
        });
        this.closeButton.onClick(() => {
            this.close();
        });

        if (this.modalStyle) {
            this.boxContainer.setStyle(this.modalStyle);
        }

        if (this.headMargin) {
            this.headContainer.setStyle(`margin-bottom:15px;`);
        }

        if (this.fixed)
            this.headContainer.remove();

        this.animateOpen();
        super.commit();
    }

    async close(): Promise<void> {
        await this.animateClose();
        this.onClose();
        if (!this.isNull()) {
            this.remove();
        }
    }

    screenRender() {
        SedestralInterface.body.render(this);
    }

    /**
     * override
     */

    public onClose(): void {

    }

    public async animateOpen(): Promise<void> {
        await this.boxContainer.scale(0.8, 1, 80);
        await this.boxContainer.fade("in", 100);
        this.boxContainer.timeOut(() => {
            this.boxContainer.setStyle("transform:unset;");
        }, 150);
    }

    public async animateClose(): Promise<void> {
        await this.boxContainer.wait([
            this.boxContainer.scale(1, 0.9, 50),
            this.boxContainer.fade("out", 80),
            this.fade("out", 80)
        ]);
    }
}