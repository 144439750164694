@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

@keyframes loadingFrame {
  0% {
    left: 0;
  }
  50% {
    left: 65%;
  }
  100% {
    left: 0;
  }
}

:global {
  .globalLoaderBackground {
    z-index: 9999;
    background: var(--white);
    width: 100%;
    height: 100%;

    @include positioning.screen-fixed;
    @include positioning.flex-globally;

    .loader {
      margin-top: -40px;
      position: absolute;

      .logo {
        height: 95px;
        z-index: 5;
        position: relative;
        width: 100%;

        .loading {
          height: 10px;
          width: 100px;
          border-radius: 10px;
          background: var(--grey-sur);
          position: relative;

          .caret {
            height: 100%;
            border-radius: 10px;
            width: 35%;
            background: var(--grey-sur-hover);
            position: absolute;


            &:local {
              animation: loadingFrame infinite 1s;
            }
          }
        }

        .logo {
          width: 42px;
          top: 3px;
          height: 42px;
          border-radius: 100%;
          float: left;
          position: relative;
          background: var(--grad-sedestral);

          @include positioning.flex-globally;

          .icon {
            height: 20px;
            width: 20px;
          }
        }

        .text {
          top: 4px;
          font-size: 32px;
          margin-left: 14px;
          color: var(--black);
          float: left;
          position: relative;

          @include texts.font("black");
        }
      }
    }

    .gradients {
      .inboxGradient {
        width: 262px;
        height: 280px;
        left: 0;
        top: 0;
        background: var(--grad-inbox);
        position: fixed;
      }

      .blogGradient {
        height: 240px;
        width: 138px;
        left: 0;
        bottom: 0;
        background: var(--grad-blog);
        position: fixed;
      }

      .sellGradient {
        height: 151px;
        width: 351px;
        right: 120px;
        bottom: 0;
        background: var(--grad-sell);
        position: fixed;
      }

      .contactsGradient {
        width: 122px;
        height: 330px;
        right: -2px;
        top: 100px;
        background: var(--grad-contacts);
        position: fixed;
      }

      .publisherGradient {
        width: 370px;
        height: 120px;
        right: calc(40% - 185px);
        top: 0;
        background: var(--grad-publisher);
        position: fixed;
      }

      .gradient {
        top: -5px;
        left: -5px;
        height: calc(100% + 5px);
        width: calc(100% + 6px);
        position: absolute;
      }

      .background {
        background-size: 18px;
        opacity: 0;
        background-repeat: space;
      }
    }
  }
}
