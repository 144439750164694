import {tableId} from "./QuillTableModule";
import Quill from "quill";
import {QuillTableBody} from "./QuillTableBody";
import {QuillTableRow} from "./QuillTableRow";
import {QuillTableCell} from "./QuillTableCell";

const Container = Quill.import('blots/container');

export class QuillTableContainer extends Container {
    balanceCells() {
        const rows = this.descendants(QuillTableRow);
        const maxColumns = rows.reduce((max, row) => {
            return Math.max(row.children.length, max);
        }, 0);
        rows.forEach(row => {
            new Array(maxColumns - row.children.length).fill(0).forEach(() => {
                let value;
                if (row.children.head != null) {
                    value = QuillTableCell.formats(row.children.head.domNode);
                }
                const blot = this.scroll.create(QuillTableCell.blotName, value);
                row.appendChild(blot);
                blot.optimize(); // Add break blot
            });
        });
    }

    cells(column) {
        return this.rows().map(row => row.children.at(column));
    }

    deleteColumn(index) {
        const [body] = this.descendant(QuillTableBody);
        if (body == null || body.children.head == null) {
            return;
        }
        body.children.forEach(row => {
            const cell = row.children.at(index);
            if (cell != null) {
                cell.remove();
            }
        });
    }

    insertColumn(index) {
        const [body] = this.descendant(QuillTableBody);
        if (body == null || body.children.head == null) {
            return;
        }
        body.children.forEach(row => {
            const ref = row.children.at(index);
            const value = QuillTableCell.formats(row.children.head.domNode);
            const cell = this.scroll.create(QuillTableCell.blotName, value);
            row.insertBefore(cell, ref);
        });
    }

    insertRow(index) {
        const [body] = this.descendant(QuillTableBody);
        if (body == null || body.children.head == null) {
            return;
        }
        const id = tableId();
        const row = this.scroll.create(QuillTableRow.blotName);
        body.children.head.children.forEach(() => {
            const cell = this.scroll.create(QuillTableCell.blotName, id);
            row.appendChild(cell);
        });
        const ref = body.children.at(index);
        body.insertBefore(row, ref);
    }

    rows() {
        const body = this.children.head;
        if (body == null) {
            return [];
        }
        return body.children.map(row => row);
    }
}

QuillTableContainer.blotName = 'table-container';
QuillTableContainer.tagName = 'TABLE';