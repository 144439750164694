import {
    VisualModalComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {OfferComponent} from "../OfferComponent";
import {CapsuleComponent} from "../../capsule/CapsuleComponent";
import {ProductType} from "../../../../../../models/product/ProductType";
import * as s from "./offer-modal.scss";
import {IOfferDetailsModel} from "../../../../../../models/offer/IOfferDetailsModel";
import {Resources} from "../../../../../../resources/Resources";

export class OfferModalComponent extends VisualModalComponent {

    public details?: IOfferDetailsModel;
    public productType: ProductType;

    constructor(productType: ProductType, details?: IOfferDetailsModel) {
        super();
        this.productType = productType;
        this.modalStyle = "width:840px;height: 93%;"
        //language=HTML
        this.name = `
            <div class="${s.componentOfferModal}">
                <div class="${s.capsule}">${this.draw(new CapsuleComponent(this.productType))}</div>
                <div class="${s.title}">
                    ${Resources.t("words.offerPricing")}
                </div>
            </div>`;

        let component = new OfferComponent(this.productType, details);
        component.onCloseModal = () => this.close();
        this.modalTemplate = this.draw(component);
    }

    commit() {
        super.commit();
        this.headContainer.setStyle(`border-bottom: 1px solid var(--grey-sur);height: 44px;`);
        this.contentContainer.setStyle(`height: calc(100% - 25px);`);
    }
}