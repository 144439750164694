@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.componentAccountBoardSwap {
  padding: 15px;

  .back {
    width: fit-content;
    padding: 8px 10px;
    border-radius: 8px;
    margin-left: -4px;
    margin-top: -4px;

    @include animations.ripple;
    @include positioning.clear;


    .icon {
      height: 16px;
      width: 16px;
      float: left;
      margin-top: 3px;

      @include icons.ico("black-left-arrow");
    }

    .text {
      font-size: 17px;
      float: left;
      margin-left: 15px;

      @include texts.font("bold");
    }
  }

  .avatarFlex {
    margin-top: 30px;
    width: 100%;
    @include positioning.flex-globally;

    .avatarContainer {
      height: 76px;
      width: 76px;
      position: relative;
    }
  }

  .name {
    width: 100%;
    text-align: center;
    font-size: 23px;
    margin-top: 12px;

    @include texts.font("bold");
  }

  .navigation {
    margin-top: 30px;

    .case {
      height: 36px;
      margin-left: -5px;
      padding: 5px;
      width: 100%;
      margin-bottom: 5px;
      border-radius: 10px;
      cursor: pointer;
      position: relative;

      @include animations.ripple;

      &.notifications {
        .icon {
          @include icons.ico("3d-notifications");
        }
      }

      &.logout {
        .icon {
          @include icons.ico("3d-disconnect");
        }
      }

      &.status {
        .icon {
          height: 14px !important;
          width: 14px !important;
        }

        .statusContent {
          visibility: visible;
        }
      }

      div[btnswitch] {
        float: right;
        margin-top: 7px;
      }

      .round {
        height: 36px;
        width: 36px;
        background: var(--grey-sur);
        border-radius: 100%;
        float: left;

        @include positioning.flex-globally;

        .icon {
          height: 18px;
          width: 18px;
          border-radius: 100%;
        }
      }

      .caseName {
        font-size: 17px;
        float: left;
        margin-left: 10px;
        margin-top: 7px;

        @include texts.font("semi-bold");


        &.status {
          margin-top: 0;
        }

        span {
          display: block;
          font-size: 10px;

          @include texts.font("medium");

          &.green {
            color: var(--green);
          }

          &.red {
            color: var(--red);
          }
        }
      }

      .pastille {
        float: right;
        height: 20px;
        width: 20px;
        background: var(--red);
        color: var(--white);
        border-radius: 100%;
        margin-top: 7px;
        font-size: 12px;

        @include texts.font("semi-bold");
        @include positioning.flex-globally;
      }
    }
  }

  .copyright {
    position: absolute;
    bottom: 15px;
    color: var(--grey-text);
    font-size: 10px;
    text-align: center;
    width: 100%;
    left: 0;


    a {
      color: var(--grey-text);
      text-decoration: underline;
    }
  }

}