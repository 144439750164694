.componentOfferModal {
  display: flex;
  align-items: center;

  .capsule {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }

  .title {
    font-size: 21px;
  }
}