import {
    StaticComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import {CapsuleComponent} from "../../components/capsule/CapsuleComponent";
import {ProductType} from "../../../../../models/product/ProductType";
import {AvatarComponent} from "../../components/avatar/AvatarComponent";
import {AccountNavigationComponent} from "../../components/account/navigation/AccountNavigationComponent";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./navigation.scss";
import {Resources} from "../../../../../resources/Resources";
import {EntityService} from "../../../../../services/entity/EntityService";
import {Network} from "../../../../../network/Network";
import {PermissionsService} from "../../../../../services/permissions/PermissionsService";
import {SiteShortcutComponent} from "../../components/site/shortcut/SiteShortcutComponent";
import {
    isTabletNative
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsTabletNative";

export class NavigationStatic extends StaticComponent {

    public rendered: boolean = false;
    public renderedAccount: boolean = false;

    public inboxCapsule: Component;
    public inboxCapsuleNotification: Component;

    public avatarContainer: Component;

    public accountNavigation: Component;
    public accountCategory: Component;
    public accountCapsule: Component;
    public accountCapsuleNotification: Component;

    public plusCategory: Component;

    public openedSubMenu: Component;

    constructor() {
        super();
        this.virtual(s.staticNavigation);
    }

    init(): void {
        if (!this.rendered) {
            //language=HTML
            let template = `
                <div class="${s.navigation}">
                    <div class="${s.topSide}">
                        <div data-path="general" data-height="0" class="${s.submenu}">
                            <a href="/general">
                                <div class="${s.category}">
                                    <div data-tooltip="${Resources.t('products.general')}"
                                         data-tooltip-position="right" class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.SEDESTRAL))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}"></div>
                        </div>
                        <div data-path="smartblog" class="${s.submenu}">
                            <a href="/smartblog">
                                <div data-tooltip="${Resources.t('products.blog')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.BLOG))}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div style="${PermissionsService.d(PermissionsService.canProduct(ProductType.HELPCENTER))}" 
                             data-path="smartdoc" 
                             class="${s.submenu}">
                            <a href="/smartdoc">
                                <div data-tooltip="${Resources.t('products.helpCenter')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.HELPCENTER))}
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div style="${PermissionsService.d(PermissionsService.canProduct(ProductType.INBOX))}"
                             data-path="inbox"
                             data-height="160" class="${s.submenu}">
                            <a href="/inbox">
                                <div data-tooltip="${Resources.t('products.inbox')}"
                                     data-tooltip-position="right" class="${s.category} ${s.inbox}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.INBOX))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}">
                                <a href="/livechats">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.livechat')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.LIVECHAT))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/mails">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.mails')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.MAILS))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/chatbot">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.chatBot')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.CHAT_BOT))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div style="${PermissionsService.d(PermissionsService.canProduct(ProductType.PUBLISHER))}"
                             data-path="publisher"
                             data-height="213" class="${s.submenu}">
                            <a style="${PermissionsService.d(!isTabletNative())}" href="/publisher">
                                <div data-tooltip="${Resources.t('products.publisher')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.PUBLISHER))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}">
                                <a href="/engagements">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.engagement')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.ENGAGEMENT))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/advertising">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.advertising')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.ADVERTISING))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/mailling">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.mailling')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.MAILLING))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/medias">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.medias')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.MEDIAS))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div style="${PermissionsService.d(PermissionsService.canProduct(ProductType.CONTACTS))}"
                                data-path="contacts"
                             data-height="54" class="${s.submenu}">
                            <a style="${PermissionsService.d(!isTabletNative())}" href="/contacts">
                                <div data-tooltip="${Resources.t('products.contacts')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.CONTACTS))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}">
                                <a href="/calendar">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.calendar')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.CALENDAR))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div data-path="sell"
                             style="${PermissionsService.d(PermissionsService.canProduct(ProductType.SELL))}"
                             data-height="160" class="${s.submenu}">
                            <a style="${PermissionsService.d(!isTabletNative())}" href="/sell">
                                <div data-tooltip="${Resources.t('products.sell')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.SELL))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}">
                                <a href="/documents">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.documents')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.DOCUMENTS))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/products">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.products')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.PRODUCTS))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/estimate">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.estimate')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.ESTIMATE))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div data-path="statistics"
                             style="${PermissionsService.d(PermissionsService.canProduct(ProductType.STATISTICS))}"
                             data-height="160" class="${s.submenu}">
                            <a href="/statistics">
                                <div data-tooltip="${Resources.t('products.statistics')}"
                                     data-tooltip-position="right" class="${s.category}">
                                    <div class="${s.capsuleContainer}">
                                        ${this.draw(new CapsuleComponent(ProductType.STATISTICS))}
                                    </div>
                                </div>
                            </a>
                            <div class="${s.list}">
                                <a href="/statistics/inbox">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.inbox')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.INBOX_GREY))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/statistics/publisher">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.publisher')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.PUBLISHER_GREY))}
                                        </div>
                                    </div>
                                </a>
                                <a href="/statistics/contacts">
                                    <div class="${s.category}">
                                        <div data-tooltip="${Resources.t('products.contacts')}"
                                             data-tooltip-position="right" class="${s.capsuleContainer}">
                                            ${this.draw(new CapsuleComponent(ProductType.CONTACTS_GREY))}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="${s.bottomSide}">
                        <div data-tooltip-position="right"
                             class="${s.category} ${s.plus}">
                            <div class="${s.capsuleContainer}">
                                <div class="${s.button}">
                                    <div class="${s.icon}"></div>
                                </div>
                            </div>
                        </div>
                        <a href="/general/site">
                            <div data-tooltip="${Resources.t("words.settings")}"
                                 data-tooltip-position="right" class="${s.category} ${s.settings}">
                                <div class="${s.capsuleContainer}">
                                    <div class="${s.button}">
                                        <div class="${s.icon}"></div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="${s.account} ${s.category}">
                            <div class="${s.capsuleContainer}">
                                <div class="${s.avatarContainer} ${s.padding}"></div>
                            </div>
                        </div>
                    </div>
                </div>
            `;

            this.append(template);
            this.rendered = true;

            super.init();
        } else {
            this.renderAvatar();
        }
        this.displayShow();
    }

    commit() {
        this.accountNavigation = this.append(`<div class="${s.navigationAccount}"></div>`);
        this.accountCategory = this.el(s.account);
        this.accountCapsule = this.accountCategory.el(s.capsuleContainer);
        this.accountCategory.onClick(() => this.renderAccount());

        this.avatarContainer = this.el(s.avatarContainer);
        this.inboxCapsule = this.el(s.inbox).el(s.capsuleContainer);

        this.plusCategory = this.el(s.plus);
        this.plusCategory.onHover(() => this.renderSiteShortcut());


        this.renderAvatar();
        this.renderNotifications();

        this.bindMenu();
        super.commitTooltips();
        super.commit();
    }

    onHash() {
        let currentSubMenu = this.findCurrentMenuCategory();

        if (currentSubMenu != undefined && (this.openedSubMenu == undefined || this.openedSubMenu.getHTMLElement() != currentSubMenu.getHTMLElement())) {
            if (this.openedSubMenu != undefined) {
                this.closeMenuCategory(this.openedSubMenu);
            }

            this.openMenuCategory(currentSubMenu);
            this.openedSubMenu = currentSubMenu;
        }

        this.updateMenu();

        super.onHash();
    }


    /**
     * menu
     */
    bindMenu() {
        this.openMenuCategory(this.findCurrentMenuCategory(), true);
        this.updateMenu();
    }

    updateMenu() {
        if (this.openedSubMenu != undefined) {
            this.els(s.category).filter(value => value.hasClass(s.selected)).forEach(value => value.removeClass(s.selected));

            this.openedSubMenu.els(s.category).forEach(value => {
                if (Network.router.pages.getPathWithoutLanguage().startsWith(value.getHTMLElement().parentElement.getAttribute("href"))) {
                    value.addClass(s.selected);
                }
            });
        }
    }

    findCurrentMenuCategory(): Component {
        let subMenus = this.els(s.submenu);
        for (let m of subMenus) {
            let hrefs = [...m.getHTMLElement().getElementsByTagName("a")].map(value => value.getAttribute("href").substring(1));
            if (hrefs.filter(value => Network.router.pages.getPathWithoutLanguage().substring(1).startsWith(value)).length > 0) {
                return m;
            }
        }
    }

    openMenuCategory(component: Component, noAnim?: boolean) {
        if (component != undefined) {
            component.el(s.list).translate({
                maxHeight: parseInt(component.getAttribute("data-height")),
                delay: noAnim ? 0 : 300
            });

            this.openedSubMenu = component;
        }
    }

    closeMenuCategory(component: Component) {
        component.el(s.list).translate({maxHeight: 0, delay: 300});
        this.openedSubMenu = undefined;
    }

    /**
     * rendering
     */
    async renderNotifications() {
        this.onReactiveObserve(() => {
            if (this.inboxCapsuleNotification != undefined) {
                this.inboxCapsuleNotification.remove();
                this.inboxCapsuleNotification = undefined;
            }

            if (this.accountCapsuleNotification != undefined) {
                this.accountCapsuleNotification.remove();
                this.accountCapsuleNotification = undefined;
            }

            if (EntityService.activeNotifications?.inboxCount) {
                //language=HTML
                this.inboxCapsuleNotification = this.inboxCapsule.append(`
                    <div class="${s.notification}">
                        ${EntityService.activeNotifications.inboxCount}
                    </div>
                `);
            }

            if (EntityService.activeNotifications?.total > 0) {
                //language=HTML
                this.accountCapsuleNotification = this.accountCapsule.append(`
                    <div class="${s.notification}">
                        ${EntityService.activeNotifications.total}
                    </div>
                `);
            }
        });
    }

    renderAvatar() {
        this.avatarContainer.clearAll();
        this.avatarContainer.render(new AvatarComponent(EntityService.activeEntity.avatar).setActive(EntityService.activeEntity));
    }

    renderAccount() {
        if (!this.renderedAccount) {
            this.renderedAccount = true;
            let navigation = new AccountNavigationComponent();
            this.accountNavigation.render(navigation);
            this.accountNavigation.displayShow();
            this.accountNavigation.translate({
                delay: 100,
                translateY: -30,
                opacity: 1,
                scale: 1
            });


            navigation.onBoardPick = () => this.closeAccount();
            this.accountNavigation.onOutsideClick(() => this.closeAccount());
        }
    }

    async closeAccount() {
        await this.accountNavigation.translate({
            delay: 100,
            translateY: 30,
            opacity: 0,
            scale: 1
        });

        this.renderedAccount = false;
        this.accountNavigation.clearAll();
        this.accountNavigation.displayHide();
        this.accountNavigation.removeAttribute("style");
    }

    renderSiteShortcut() {
        let shortcut = new SiteShortcutComponent(this.plusCategory);
        shortcut.create();
    }

}