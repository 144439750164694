import {
    VisualSwapComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/swap/VisualSwapComponent";
import {NotificationsComponent} from "../NotificationsComponent";
import {NotificationService} from "../../../../../../services/notification/NotificationService";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Resources} from "../../../../../../resources/Resources";

export class NotificationsSwapComponent extends VisualSwapComponent {
    constructor(component: Component) {
        super(Resources.t("words.notificationsTitle"), component, "100%");
    }

    commit() {
        super.commit();
        this.init();
    }

    async init() {
        let notificationsComponent = new NotificationsComponent();
        this.contentContainer.render(notificationsComponent);

        notificationsComponent.setLoading();
        let notifications = await NotificationService.findAll(this);
        notificationsComponent.renderNotifications(notifications == undefined ? [] : notifications.reverse());
    }
}