import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {OfferContentComponent} from "../content/OfferContentComponent";
import * as s from "./offer-template.scss";
import {IOfferProductTemplateModel} from "../../../../../../models/offer/product/templates/IOfferProductTemplateModel";
import {Resources} from "../../../../../../resources/Resources";
import {
    IOfferProductBundleTemplateModel
} from "../../../../../../models/offer/product/bundles/IOfferProductBundleTemplateModel";
import {OfferProductLevel} from "../../../../../../models/offer/product/OfferProductLevel";
import {IOfferPeriodTypeModel} from "../../../../../../models/offer/IOfferPeriodTypeModel";
import {OfferPeriodType} from "../../../../../../models/offer/product/OfferPeriodType";
import {OfferTemplateIconComponent} from "./icon/OfferTemplateIconComponent";

export class OfferTemplateComponent extends Component {

    public productTemplate: IOfferProductTemplateModel;
    public period: IOfferPeriodTypeModel;
    public bundle: IOfferProductBundleTemplateModel;

    public priceContainer: Component;
    public contentContainer: Component;

    constructor(template: IOfferProductTemplateModel, period: IOfferPeriodTypeModel) {
        super();
        this.productTemplate = template;
        this.period = period;
        this.bundle = template.bundles[0];

        //language=HTML
        this.template = `
            <div class="${s.componentOfferTemplate} ${this.productTemplate.level == OfferProductLevel.PREMIUM ? s.premium : ``}">
                <div class="${s.top}">
                    <div class="${s.name}">
                        ${Resources.t(`words.offerName${template.type}`)}
                    </div>
                    <div class="${s.icon}">
                        ${this.draw(new OfferTemplateIconComponent(template.type))}
                    </div>
                    <div class="${s.price}"></div>
                    <div class="${s.description}">
                        <div class="${s.engage}">${Resources.t("words.noCommitment")}</div>
                        ${Resources.t(`words.offerDescription${template.type}`)}
                    </div>
                </div>
                <div class="${s.bottom}"></div>
            </div>
        `;
    }

    commit() {
        this.priceContainer = this.el(s.price);
        this.contentContainer = this.el(s.bottom);

        this.renderPrice();
        this.renderContent();
        super.commit();
    }

    renderPrice() {
        let price = this.bundle.priceInitial / 100;

        if (this.period.periodType == OfferPeriodType.ANNUAL) {
            price = price * 12;
            price = price - (price * this.period.advantagePercentage);
        }

        this.priceContainer.clearAll();
        this.priceContainer
            .setText(this.productTemplate.level == OfferProductLevel.FREE ? Resources.t("words.free") : `${Math.round(price)}€ /${Resources.t(`words.offerPeriodTitle${this.period.periodType}`)}`)
    }

    renderContent() {
        this.contentContainer.render(new OfferContentComponent(this.productTemplate, this.bundle.solutions.map(value => {
            return `${value.quantityInitial} ${Resources.t(`words.offerSolution${this.productTemplate.level}${this.productTemplate.productType}${value.type}`)}`;
        }), undefined));
    }

    setOptions() {

    }

    setPeriod(period: IOfferPeriodTypeModel) {
        this.period = period;
        this.renderPrice();
    }
}