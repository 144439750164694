@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";


.globalEmptyBasic {
  height: 100%;
  width: 100%;
  @include positioning.flex-globally;

  .emptyContent {
    position: relative;
    width: 100%;

    .loader{
      position: relative;
      margin-top: 50px;
    }

    .emoji {
      font-size: 50px;
      margin-bottom: 15px;
      text-align: center;

      @include positioning.flex-globally;
    }

    .text {
      width: 70%;
      left: 15%;
      margin-bottom: 20px;
      font-size: 16px;
      text-align: center;
      position: relative;
      color: var(--black);

      @include texts.font("bold");
    }


    .buttonContainer {
      width: 100%;
      position: relative;

      @include positioning.flex-globally;

      .button {
        @include buttons.grey;
        @include texts.font("bold");

        padding: 10px 30px;
        width: fit-content;
        background: var(--grey-sur);
        border-radius: 30px;
        font-size: 16px;
        margin-top: 25px;
        position: relative;

        &.loading {
          display: flex;
          align-items: center;
          justify-content: center;
          color: transparent !important;
        }

        svg {
          height: 60%;

          circle {
            stroke-width: 5px;
          }
        }
      }
    }
  }
}