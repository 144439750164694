import {ISiteColumnsModel} from "../../../../models/site/columns/ISiteColumnsModel";
import {Resources} from "../../../../resources/Resources";
import {ContactNewsletterState} from "../../../../models/contact/ContactNewsletterState";
import {ContactOrigin} from "../../../../models/contact/ContactOrigin";
import {SiteColumnsService} from "../SiteColumnsService";

export class SiteColumnsContactService extends SiteColumnsService {

    static standard: ISiteColumnsModel;

    public static dispose(): void {

    }

    public static init(): void {

    }


    /**
     * store
     */


    public static store(columns: ISiteColumnsModel): ISiteColumnsModel {
        return this.storeAbstract(this.standard, columns);
    }

    public static storeStandard(columns: ISiteColumnsModel) {
        let frontPossibleValues = {
            "gender": [
                {id: "M", labels: [{language: "fr", name: Resources.t("words.male")}]},
                {id: "F", labels: [{language: "fr", name: Resources.t("words.female")}]},
            ],
            "origin": [
                {
                    id: ContactOrigin.LIVECHAT,
                    labels: [{language: "fr", name: Resources.t("products.livechat")}]
                },
                {id: ContactOrigin.MAIL, labels: [{language: "fr", name: Resources.t("products.mails")}]},
                {id: ContactOrigin.BLOG, labels: [{language: "fr", name: Resources.t("products.blog")}]},
                {
                    id: ContactOrigin.HELPCENTER,
                    labels: [{language: "fr", name: Resources.t("products.helpCenter")}]
                },
                {
                    id: ContactOrigin.FACEBOOK_PAGE,
                    labels: [{language: "fr", name: Resources.t("products.facebookPage")}]
                },
                {
                    id: ContactOrigin.INSTAGRAM,
                    labels: [{language: "fr", name: Resources.t("products.instagram")}]
                },
                {
                    id: ContactOrigin.UNKNOWN,
                    labels: [{language: "fr", name: Resources.t("words.unknown")}]
                },
                {
                    id: ContactOrigin.CUSTOM_CREATE,
                    labels: [{language: "fr", name: Resources.t("words.collaborators")}]
                }
            ],
            "newsletter": [
                {
                    id: "" + ContactNewsletterState.SUBSCRIBER,
                    labels: [{language: "fr", name: Resources.t("words.subscriber")}]
                },
                {
                    id: "" + ContactNewsletterState.NONE,
                    labels: [{language: "fr", name: Resources.t("words.nonSubscriber")}]
                },
            ]
        };

        columns.columns.forEach(column => {
            if (frontPossibleValues[column.id]) {
                column.possibleValues = frontPossibleValues[column.id];
            }
        });

        this.standard = columns;
    }
}