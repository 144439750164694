import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./mobile-header-avatar.scss";
import {AvatarComponent} from "../../../../../panel/components/components/avatar/AvatarComponent";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {
    AccountBoardSwapComponent
} from "../../../../../panel/components/components/account/board/swap/AccountBoardSwapComponent";

export class MobileHeaderAvatarComponent extends Component {

    public notificationContainer: Component;

    constructor() {
        super();

        //language=HTML
        this.template = `
            <div class="${s.componentMobileHeaderAvatar}">
                ${this.draw(new AvatarComponent(EntityService.activeEntity.avatar))}
            </div>
        `;
    }

    commit() {
        this.onClick(() => {
            let board = new AccountBoardSwapComponent();
            board.create();
        });

        this.renderNotifications();
        super.commit();
    }

    async renderNotifications() {
        this.onReactiveObserve(() => {
            if (this.notificationContainer != undefined) {
                this.notificationContainer.remove();
                this.notificationContainer = undefined;
            }

            if (EntityService.activeNotifications.total > 0) {
                //language=HTML
                this.notificationContainer = this.append(`
                    <div class="${s.notification}">
                        ${EntityService.activeNotifications.total}
                    </div>
                `);
            }
        });
    }

}