import Quill from "quill";

const Container = Quill.import('blots/container');

export class QuillTableRow extends Container {
    checkMerge() {
        if (super.checkMerge() && this.next.children.head != null) {
            const thisHead = this.children.head.formats();
            const thisTail = this.children.tail.formats();
            const nextHead = this.next.children.head.formats();
            const nextTail = this.next.children.tail.formats();
            return (
                thisHead.table === thisTail.table &&
                thisHead.table === nextHead.table &&
                thisHead.table === nextTail.table
            );
        }
        return false;
    }

    optimize(...args) {
        super.optimize(...args);
        this.children.forEach(child => {
            if (child.next == null) {
                return;
            }
            const childFormats = child.formats();
            const nextFormats = child.next.formats();
            if (childFormats.table !== nextFormats.table) {
                const next = this.splitAfter(child);
                if (next) {
                    next.optimize();
                }
                // We might be able to merge with prev now
                if (this.prev) {
                    this.prev.optimize();
                }
            }
        });
    }

    rowOffset() {
        if (this.parent) {
            return this.parent.children.indexOf(this);
        }
        return -1;
    }

    table() {
        return this.parent && this.parent.parent;
    }
}

QuillTableRow.blotName = 'table-row';
QuillTableRow.tagName = 'TR';