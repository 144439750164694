@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.staticPageLoaders {
  left: 61px;
  width: calc(100% - 61px);
  position: fixed;
  height: 100%;
  z-index: 99999999;
  background: var(--white);


  .content {
    height: 100%;
    width: 100%;

    @include positioning.flex-globally;

    .relative {
      width: fit-content;
      position: relative;
      pointer-events: none;
      user-select: none;
      height: 175px;
      margin-left: -25px;

      .capsuleContainer {
        height: 80px;
        width: 80px;
        margin-right: 10px;
        float: left;
        position: relative;
      }

      .capsule {
        height: 80px;
        width: 80px;
        position: absolute;
        left: 100%;
        margin-top: -11px;
      }

      .name {
        float: left;
        font-size: 56px;
        line-height: 35px;
        opacity: 0;

        @include texts.font("bold");

        .company {
          margin-left: 2px;

          span {
            font-size: 15px;

            @include texts.font("semi-bold");
          }

          font-size: 19px;

          @include texts.font("extra-bold");
        }
      }
    }
  }
}