@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalPromptConfirmation {
  .description {
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .buttons {
    .button {
      height: 20px;
      padding: 10px;
      border-radius: 35px;
      font-size: 14px;
      width: calc(50% - 25px);
      position: relative;

      @include positioning.clear;
      @include positioning.flex-globally;
      @include texts.font("semi-bold");

      svg {
        height: 22px;
        width: 22px;

        circle {
          stroke: var(--white);
        }
      }

      &.cancel {
        float: left;
        @include buttons.grey;
      }

      &.continue {
        float: right;
        @include buttons.green;
      }
    }
  }

}