export enum OfferState {
    CONFIRMED = 0, //le client doit confirmer qu'il va acheter cette offre
    PAYMENT = 1, //le client doit payer pour activer l'offre
    SUSPENDED = 2, //l'offre est suspendue
    CLOSED = 3, //l'offre est expirée
    SCHEDULED = 4, //l'offre va être activée prochainement
    IN_PROGRESS = 5, //offre en cours sans problème
    FAILURE_TO_PAY = 6, //offre active, mais un defaut de paiement est en cours
    DEFINITIVE_SCHEDULED_CANCEL = 7 //l'offre va être annulée prochainement, plus possible de revenir en arrière

}