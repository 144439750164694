import {Component} from "../Component";
import * as s from "../../../../sedestral-interface-sass/imports/swapbar.scss";

export class ComponentSwapBar {
    private component: Component;
    private onDispose: () => void;

    constructor(component: Component, onDispose?: () => void) {
        this.component = component;
        this.onDispose = onDispose;
        this.init();
    }

    init() {
        this.component.addClass(s.swap);
        //language=HTML
        let selector = this.component.prepend(`
            <div swapBar class="${s.swapBar}">
                <div class="${s.bar}"></div>
            </div>
        `);

        //let swiper = onSwipe(selector);
        //  swiper.onDown = () => this.onDispose();
    }
}