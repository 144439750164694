import {
    VisualSwapFixeComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/swap/fixe/VisualSwapFixeComponent";
import * as s from "./account-board-swap.scss";
import {AvatarComponent} from "../../../avatar/AvatarComponent";
import {EntityService} from "../../../../../../../services/entity/EntityService";
import {
    ButtonSwitchComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/check/switch/ButtonSwitchComponent";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AccountService} from "../../../../../../../services/account/AccountService";
import {
    LoaderLightComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {AccountSessionService} from "../../../../../../../services/account/session/AccountSessionService";
import {NotificationsSwapComponent} from "../../../notification/swap/NotificationsSwapComponent";
import {Resources} from "../../../../../../../resources/Resources";
import {Network} from "../../../../../../../network/Network";

export class AccountBoardSwapComponent extends VisualSwapFixeComponent {

    public backButton: Component;
    public notificationsButton: Component;
    public statusButton: Component;
    public logoutButton: Component;
    public statusContentContainer: Component;
    public statusSwitch: ButtonSwitchComponent;

    constructor() {
        super();
        this.statusSwitch = new ButtonSwitchComponent(EntityService.activeEntity.active);

        //language=HTML
        this.template = `
            <div class="${s.componentAccountBoardSwap}">
                <div class="${s.back}">
                    <div class="${s.icon}"></div>
                    <div class="${s.text}">${Resources.t("sentences.account.profileTitle")}</div>
                </div>
                <div class="${s.avatarFlex}">
                    <div class="${s.avatarContainer}">
                        ${this.draw(new AvatarComponent(EntityService.activeEntity.avatar))}
                    </div>
                </div>
                <div class="${s.name}">
                    ${this.reactive(() => EntityService.activeEntity.name)}
                </div>
                <div class="${s.navigation}">
                    <div class="${s.case} ${s.status}">
                        <div class="${s.statusContent}"></div>
                        ${this.draw(this.statusSwitch)}
                    </div>
                    <div class="${s.case} ${s.notifications}">
                        <div class="${s.round}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.caseName}">
                            ${Resources.t("words.notifications")}
                        </div>
                        ${EntityService.activeNotifications.total > 0 ? `<div class="${s.pastille}">${EntityService.activeNotifications.total}</div>` : ``}
                    </div>
                    <div class="${s.case} ${s.logout}">
                        <div class="${s.round}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.caseName}">
                            ${Resources.t("words.deconnexion")}
                        </div>
                    </div>
                </div>
                <div class="${s.copyright}">
                    <a href="${Network.router.pages.getWebsiteUrl('cgu')}" target="_blank">${Resources.t("sentences.account.copyright.cgu")}</a> |
                    <a href="${Network.router.pages.getWebsiteUrl('cgv')}" target="_blank">${Resources.t("sentences.account.copyright.cgv")}</a> |
                    <a href="${Network.router.pages.getWebsiteUrl('policy')}" target="_blank">${Resources.t("sentences.account.copyright.policy")}</a> |
                    ${Resources.t("sentences.account.copyright.name")} ${new Date().getFullYear()}
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();

        this.statusContentContainer = this.el(s.statusContent);
        this.notificationsButton = this.el(s.notifications);
        this.logoutButton = this.el(s.logout);
        this.statusButton = this.el(s.status);
        this.backButton = this.el(s.back);

        this.backButton.onClick(() => this.dispose(), false, true);
        this.statusSwitch.onStateChange = async (checked) => {
            this.statusSwitch.setLoading();
            await AccountService.activity(this.statusSwitch.checked, this);
            this.statusSwitch.removeLoading();
        };
        this.statusSwitch.unClickable();
        this.statusButton.onClick(() => this.statusSwitch.stateChange(!this.statusSwitch.checked), false, true);
        this.logoutButton.onClick(async () => {
            this.logoutButton.save();
            this.logoutButton.clear();
            this.logoutButton.render(new LoaderLightComponent());
            await AccountSessionService.logout(this);
        }, false, true);

        this.notificationsButton.onClick(() => {
            let swap = new NotificationsSwapComponent(this.notificationsButton);
            swap.create();
        }, false, true);

        this.onReactiveObserve(() => this.renderStatusContent());
    }


    renderStatusContent() {
        this.statusContentContainer.clearAll();
        //language=HTML
        this.statusContentContainer.append(`
            <div class="${s.round}">
                <div style="${EntityService.activeEntity.active ? `background: var(--green);` : `background: var(--red);`}"
                     class="${s.icon}"></div>
            </div>
            <div class="${s.caseName} ${s.status}">
                ${Resources.t("words.status")}
                <span class="${EntityService.activeEntity.active ? s.green : s.red}">
                    ${EntityService.activeEntity.active ? `${Resources.t("words.active")}` : `${Resources.t("words.inactive")}`}
                </span>
            </div>
        `);
    }

}