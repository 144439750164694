@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentOfferContent {
  .title {
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .offers {
    margin: 8px -5px;
    font-size: 15px;

    li {
      list-style-type: disc;
    }
  }
}