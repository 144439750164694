import {Component} from "../Component";
import {onDrag} from "../funcs/OnDrag";

export class Swiper {
    public selector: Component;

    public yCurrent: number = 0;

    constructor(selector: Component, component: Component) {
        this.selector = selector;

        component.addListener('touchend', (evt) => {
            let percent = component.getHeight() / 4;
            if (this.yCurrent > percent) {
                this.onDown();
            } else {
                component.setStyle(`top:0px;transition:0.6s;`);
            }

            this.yCurrent = 0;
        });

        onDrag(selector, (left, top) => {
            this.yCurrent = top;
            component.setStyle(`top:calc(${top}px - env(safe-area-inset-top));transition:0s;`);
        });
    }

    /**
     * override
     */

    onLeft() {

    }

    onRight() {

    }

    onUp() {

    }

    onDown() {

    }
}