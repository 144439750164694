import {VisualSwapComponent} from "../VisualSwapComponent";
import * as s from "../swap.scss";
import * as t from "./swap-fixe.scss";

export class VisualSwapFixeComponent extends VisualSwapComponent {

    constructor() {
        super(undefined, undefined, undefined);
        //language=HTML
        this.template = `
            <div style="transform: translateY(150px);" class="${s.visualSwap} ${t.visualSwapFixe}">
                template
            </div>
        `;
    }

    bind() {

    }

    bindSwiper() {

    }

    getTemplate(): string {
        //language=HTML
        return `
            <div style="transform: translateY(150px);" class="${s.visualSwap} ${t.visualSwapFixe}">
                ${this.template}
            </div>
        `;
    }

    async animateOpen(): Promise<void> {
        return await this.translate({translateY: 0, opacity: 1, delay: 400});
    }

    async animateDispose(): Promise<void> {
        return await this.translate({translateY: 150, opacity: 0, delay: 400});
    }

}