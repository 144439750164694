import {
    VisualClickTooltipWhiteComponent
} from "../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {ISelectBoxEntry} from "../types/ISelectBoxEntry";
import * as s from "./selectbox-tooltip.scss";
import {IVisualSelectBox} from "../types/IVisualSelectBox";
import {Resources} from "../../../../resources/Resources";
import {txtToSearch} from "../../../sedestral-interface-component/utilities/TxtToSearch";
import {txtStrip} from "../../../sedestral-interface-component/utilities/TxtStrip";

export class VisualSelectBoxTooltipComponent extends VisualClickTooltipWhiteComponent {
    public settings: IVisualSelectBox;

    public casesContainer: Component;
    public casesScrollable: Component;
    public buttonsComponents: Component[];

    public typedWord: string;

    public selectedValue: string | number | string[];
    public selectedButton: Component;

    constructor(component: Component, settings: IVisualSelectBox, selectedValue?: string | number | string[]) {
        super(component, 0, settings.width ? settings.width : component.getWidth());
        this.settings = settings;
        this.buttonsComponents = [];
        this.typedWord = "";
        if (this.settings.entries.length > 0) {
            this.selectedValue = selectedValue;
        }


        //language=HTML
        this.template = `
            <div class="${s.globalSelectboxTooltip}">
                <div class="${s.casesScrollable}">
                    <div class="${s.casesContainer}"></div>
                </div>
            </div>
        `;

        this.mobileStyle(true);
        this.autoMaxHeight(true);
    }

    commit() {
        this.casesContainer = this.el(s.casesContainer);
        this.casesScrollable = this.el(s.casesScrollable);

        if (this.settings.search) {
            this.casesScrollable.setStyle(`height:calc(100% - 32px);`);
        }
        this.casesScrollable.scrollable();

        this.init();
        this.swapable(() => this.dispose());
        super.commit();
    }


    init() {
        this.setStyle(`padding:5px;`);

        if (this.settings.search) {
            //language=HTML
            this.el(s.globalSelectboxTooltip).prepend(`
                <div class="${s.searchContainer}">
                    <div class="${s.icon}"></div>
                    <input placeholder="${Resources.t("words.search")}" class="${s.input}"/>
                </div>
            `);

            let input = this.el(s.input);
            input.onKeyUp(() => this.renderButtons(input.getValue()));
            input.focus();
        }

        this.renderButtons();
    }

    renderButtons(search?: string) {
        this.casesContainer.clearAll();
        this.settings.entries.filter(value => {
                let searchValue = this.settings.searchDescription ? value.description : value.label;
                return search == undefined ||
                    (this.settings.searchInclude ?
                        txtStrip(txtToSearch(searchValue)).includes(txtToSearch(search)) :
                        txtStrip(txtToSearch(searchValue)).startsWith(txtToSearch(search)));
            }
        ).forEach(entry => {
            //language=HTML
            let button = this.casesContainer.append(`
                <div class="${s.case} ${(this.settings.multiple ? (this.selectedValue as string[]).includes("" + entry.value) : this.selectedValue == entry.value) ? s.selected : ``}">
                    ${entry.label ? entry.label : ``}
                </div>
            `);

            button['entry'] = entry;
            button.onClick(async () => {
                this.selectedValue = entry.value;
                if (!this.settings.multiple) {
                    if (this.settings.selectedValueNullable) {
                        if (this.selectedButton && this.selectedButton != button) {
                            this.selectedButton.removeClass(s.selected);
                        }

                        if (button.hasClass(s.selected)) {
                            this.selectedButton = undefined;
                            button.removeClass(s.selected);
                        } else {
                            this.selectedButton = button;
                            button.addClass(s.selected);
                            await this.dispose();
                        }
                    } else {
                        await this.dispose();
                    }
                } else {
                    if (button.hasClass(s.selected)) {
                        button.removeClass(s.selected);
                    } else {
                        button.addClass(s.selected);
                    }
                }

                this.onSelect(entry);
            }, true);

            if (entry.description) {
                button.addClass(s.desc);
                //language=HTML
                button.append(`
                    <div class="${s.description}">
                        ${entry.description}
                    </div>
                `);
            }

            this.buttonsComponents.push(button);
        });
    }


    /**
     * override
     */

    onSelect(entry: ISelectBoxEntry) {

    }
}