@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentOfferQuote {
  height: 100%;

  .back {
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      width: 10px;
      height: 10px;
      margin-top: 3px;
      margin-right: 5px;

      @include icons.ico("black-left-arrow");
    }
  }

  .contentContainer {
    padding: 10px;
    border: 1px solid var(--grey-sur);
    border-radius: 12px;
    margin-bottom: 10px;

    .template {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 10px;

      .iconContainer {
        height: 22px;
        margin-right: 10px;
        width: 22px;
        position: relative;
      }

      .name {
        font-size: 18px;

        @include texts.font("semi-bold");
      }
    }
  }

  .advantageCode {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    gap: 25px;
    font-size: 14px;

    .inputContainer {
      width: 170px;

      input {
        height: 36px;
        border: 1px solid var(--grey-sur) !important;
      }
    }
  }

  .inputs {

    .label {
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 25px;
      display: flex;

      span {
        margin-left: 10px;
      }
    }

  }

  .priceDetails {
    border: 1px solid var(--grey-sur);
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 15px;
    position: relative;

    .title {
      font-size: 16px;
      margin-bottom: 10px;

      @include texts.font("semi-bold");

      span {
        font-size: 13px;

        @include texts.font("medium");
      }

      .description {
        font-size: 14px;
        color: var(--grey-text-dark);

        @include texts.font("medium");
      }
    }

    .priceVAT {
      font-size: 14px;

      &.advantage {
        color: var(--grey-text-very-dark);
      }
    }

    .price {
      font-size: 20px;

      @include texts.font("semi-bold");
    }


    .invoice {
      position: absolute;
      right: 12px;
      top: 12px;
      background: var(--blue);
      color: var(--white);
      padding: 6px 10px;
      border-radius: 10px;
      font-size: 14px;
      display: flex;
      align-items: center;

      .icon {
        height: 12px;
        width: 12px;
        margin-left: 10px;
        filter: var(--icon-white);

        @include icons.ico("black-download");
      }
    }
  }

  .stripe {
    padding: 14px;
    border: 1px solid var(--grey-sur);
    border-radius: 12px;
    margin-bottom: 25px;

    .error {
      border: 2px solid var(--red);
      border-radius: 5px;
      margin-bottom: 25px;
      font-size: 14px;
      padding: 14px;
      color: var(--red-real);
    }
  }

  .scheduled {
    border-top: 1px solid var(--grey-sur);
    margin-bottom: 24px;
    padding-top: 15px;
    font-size: 14px;
    text-align: right;
  }

  .emptyContainer {
    position: relative;
    height: 96px;
    margin-bottom: 14px;
    margin-top: 14px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--grey-sur);

    .icon {
      height: 50px;
      width: 50px;

      @include icons.ico("3d-time");
    }
  }

  .cancel {
    div[btns] {
      background: var(--offer-cancel);
    }
  }
}