import {tableId} from "./QuillTableModule";
import Quill from "quill";

const Block = Quill.import('blots/block');

export class QuillTableCell extends Block {
    static create(value) {
        const node = super.create();
        if (value) {
            node.setAttribute('data-row', value);
        } else {
            node.setAttribute('data-row', tableId());
        }
        return node;
    }

    static formats(domNode) {
        if (domNode.hasAttribute('data-row')) {
            return domNode.getAttribute('data-row');
        }
        return undefined;
    }

    cellOffset() {
        if (this.parent) {
            return this.parent.children.indexOf(this);
        }
        return -1;
    }

    format(name, value) {
        if (name === QuillTableCell.blotName && value) {
            this.domNode.setAttribute('data-row', value);
        } else {
            super.format(name, value);
        }
    }

    row() {
        return this.parent;
    }

    rowOffset() {
        if (this.row()) {
            return this.row().rowOffset();
        }
        return -1;
    }

    table() {
        return this.row() && this.row().table();
    }
}

QuillTableCell.blotName = 'table';
QuillTableCell.tagName = 'TD';