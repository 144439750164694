enum PaymentProviderType {
    STRIPE = 0,
    SHOPIFY = 1
}

class PaymentProvider {
    id: PaymentProviderType;
    invoice: boolean;
    cb: boolean;
    address: boolean;
    cancelScheduled: boolean

    constructor(id: PaymentProviderType, invoice: boolean, cb: boolean, address: boolean, cancelScheduled: boolean) {
        this.id = id;
        this.invoice = invoice;
        this.cb = cb;
        this.address = address;
        this.cancelScheduled = cancelScheduled;
    }
}

const stripe = new PaymentProvider(PaymentProviderType.STRIPE, true, true, true, true);
const shopify = new PaymentProvider(PaymentProviderType.SHOPIFY, false, false, false, false);

const paymentProvidersMap: { [key: number]: PaymentProvider } = {
    [PaymentProviderType.STRIPE]: stripe,
    [PaymentProviderType.SHOPIFY]: shopify
};

function getPaymentProviderById(id: number): PaymentProvider | undefined {
    return paymentProvidersMap[id];
}

export { PaymentProviderType, PaymentProvider, stripe, shopify, getPaymentProviderById };
