export enum OfferProductTemplateType {
    HELP_CENTER_FREE = 1,
    HELP_CENTER_BASIC,
    HELP_CENTER_AGENCY,
    BLOG_FREE,
    BLOG_BASIC,
    BLOG_PRO,
    INBOX,
    PUBLISHER,
    CONTACTS
}