export enum ProductType {
    SEDESTRAL = 1,
    INBOX = 2,
    PUBLISHER = 3,
    STATISTICS = 4,
    CONTACTS = 5,
    CHANNELS = 6,
    LIVECHAT = 7,
    PANEL = 8,
    GENERAL = 9,
    CHAT_BOT = 10,
    MAILS = 11,
    SELL = 12,
    ENGAGEMENT = 13,
    ADVERTISING = 14,
    MAILLING = 15,
    MEDIAS = 16,
    CALENDAR = 17,
    DOCUMENTS = 18,
    PRODUCTS = 19,
    ESTIMATE = 20,
    CONTACTS_GREY = 21,
    PUBLISHER_GREY = 22,
    INBOX_GREY = 23,
    HELPCENTER = 24,
    WEBSITE = 25,
    CLIENT_SERVICE = 26,
    BLOG = 27,
    MOBILE = 28,
    COMMUNITY = 29,
}