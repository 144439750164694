import {observable} from "@nx-js/observer-util";
import {Services} from "../Services";
import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {IMailModel} from "../../models/mail/IMailModel";
import {MailDomainService} from "./domain/MailDomainService";
import {MailServerService} from "./server/MailServerService";
import {MailAliasService} from "./alias/MailAliasService";
import {MailRedirectService} from "./redirect/MailRedirectService";
import {IAvatarModel} from "../../models/avatar/IAvatarModel";
import {AvatarType} from "../../models/avatar/AvatarType";
import {ProductType} from "../../models/product/ProductType";
import {ErrorCode} from "../../network/status/error/ErrorCode";
import {Resources} from "../../resources/Resources";

export class MailService {
    public static mails: IMailModel[] = observable([]);

    public static init() {
        MailDomainService.init();
        MailServerService.init();
        MailAliasService.init();
        MailRedirectService.init();
    }

    public static dispose() {
        this.mails = observable([]);
    }

    /**
     * rest
     */
    public static async create(name: string, senderName: string, domainId: string, diskSpaceLimit: number, serverId: string, assignedId?: string, component?: INetworkComponent): Promise<IMailModel | undefined> {
        Services.handleErrors(component, [
            {status: HttpStatus.INSUFFICIENT_STORAGE, message: Resources.t("words.mailDiskError")},
            {status: HttpStatus.BAD_REQUEST, message: Resources.t("words.mailDiskAllowError")},
            {errorCode: ErrorCode.MAIL_FORMAT, message: Resources.t("errors.MAIL_FORMAT"), displayValue: true},
        ]);

        let request = await Network.post(ProductType.PANEL, "/mails",
            {
                name: name,
                senderName: senderName,
                domainId: domainId,
                serverId: serverId,
                assignedId: assignedId,
                diskSpaceLimit: diskSpaceLimit
            }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    public static async delete(mail: IMailModel, component?: INetworkComponent): Promise<boolean> {
        let request = await Network.delete(ProductType.PANEL, `/mails/${mail.id}`, component);
        if (request.status == HttpStatus.OK) {
            this.unStore(mail);
            return true;
        }

        return false;
    }

    public static async findByServer(serverId: string, component?: INetworkComponent): Promise<IMailModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/${serverId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    public static async findBySite(siteId: string, component?: INetworkComponent): Promise<IMailModel[]> {
        //TODO store and cache
        let request = await Network.get(ProductType.PANEL, `/mails/site/${siteId}`, component);
        if (request.status == HttpStatus.OK) {
            return this.storeAll(request.data);
        }

        return [];
    }

    /**
     * values
     */

    public static senderName(mail: IMailModel): string {
        return mail.assigned ? mail.assigned.name : mail.senderName;
    }

    public static avatar(mail: IMailModel): IAvatarModel {
        if (mail.assigned) {
            return mail.assigned.avatar;
        }

        return {
            type: AvatarType.MAIL,
            color: {
                color: mail.color
            }
        }
    }

    /**
     * store
     */

    public static storeAll(mails: IMailModel[]): IMailModel[] {
        for (let key in mails)
            mails[key] = this.store(mails[key]);

        return Services.storeAll(mails);
    }

    public static store(mail: IMailModel): IMailModel {
        return Services.store("id", this.mails, mail);
    }

    public static unStore(mail: IMailModel) {
        Services.unStore("id", this.mails, mail);
    }
}