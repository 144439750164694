import {
    VisualModalComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {SitesComponent} from "../SitesComponent";
import {ISiteModel} from "../../../../../../models/site/ISiteModel";
import {Resources} from "../../../../../../resources/Resources";

export class SitesModalComponent extends VisualModalComponent {
    public sitesComponent: SitesComponent;
    public sites: ISiteModel[];
    public checkedIds: string[];

    constructor(sites?: ISiteModel[], checkedIds?: string[]) {
        super();
        this.sites = sites;
        this.checkedIds = checkedIds;
        this.sitesComponent = new SitesComponent(this.sites, this.checkedIds);
        this.name = Resources.t("words.selectWorkspace");
    }

    commit() {
        super.commit();
        this.boxContainer.setStyle(`height:300px;`);
        this.contentContainer.clearAll();

        this.contentContainer.render(this.sitesComponent);
    }
}