@use "./../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "./../../../../../../sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentMobileHeaderAvatar {
  height: 100%;
  width: 100%;

  .notification {
    background: var(--red);
    position: absolute;
    font-size: 9px;
    border-radius: 100%;
    border: 3px solid var(--white);
    color: var(--white);
    height: 12px;
    width: 12px;
    right: -5px;
    top: -5px;

    @include texts.font("semi-bold");
    @include positioning.flex-globally;
  }
}