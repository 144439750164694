@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalEmptyNetwork {
  position: fixed;
  z-index: 99999999999;
  background: var(--white-alpha);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  backdrop-filter: saturate(180%) blur(20px);

  .flex {
    @include positioning.flex-globally;

    .icon {
      height: 200px;
      width: 200px;

      @include icons.ico("3d-emoji");
    }

    .text {
      margin-top: 40px;
      width: 520px;
      font-size: 19px;
      text-align: center;

      @include texts.font("semi-bold");
    }

    .logo {
      position: absolute;
      bottom: 30px;

      .capsuleContainer {
        height: 26px;
        width: 26px;
        position: relative;
        float: left;
      }

      .name {
        float: left;
        margin-top: 4px;
        margin-left: 7px;

        @include texts.font("extra-bold");
      }
    }

  }
}