import {PageStatus} from "./PageStatus";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {Network} from "../../../../network/Network";
import {Resources} from "../../../../resources/Resources";

export abstract class PageComponent extends Component {

    public title: string;
    public path: string;
    public description: string;
    public keywords: string;
    public image: string;
    public favicon: string;
    public ogUrl: string;
    public ogSiteName: string;
    public ogType: string;
    public alternateLang: Map<string, string>;
    public container: Component;
    public status: PageStatus;

    protected constructor() {
        super();
        this.image = Network.vendor + "images/capture-blog.webp";
        this.ogUrl = Network.router.pages.getUrlWithLang();
        this.ogSiteName = "Sedestral";
        this.ogType = "website";
        this.container = undefined;
        this.template = "<div>page</div>";
        this.status = new PageStatus();

        let alternateLanguages = new Map<string, string>();
        Resources.languages.forEach(lang => {
            alternateLanguages.set(lang, Network.router.pages.getUrlWithLang(lang));
        });
        this.alternateLang = alternateLanguages;
    }

    public updateMetas(pageName: string) {
        const {title, description, keywords} = this.getPageData(pageName);
        this.title = title;
        this.description = description;
        this.keywords = keywords;
    }

    abstract onHash(): void;

    abstract init(): void;

    getTemplate(): string {
        this.init();
        return super.getTemplate();
    }

    destroy() {
        this.nullify();
        this.committed = false;
    }

    renderNoneSite() {
    }

    renderNoneRole() {
    }

    private getPageData(pageName: string) {
        const keyPrefix = `pagesDatas.${pageName}.`;
        return {
            title: Resources.t(`${keyPrefix}title`),
            description: Resources.t(`${keyPrefix}description`),
            keywords: Resources.exist(`${keyPrefix}keywords`) ? Resources.t(`${keyPrefix}keywords`) : undefined
        };
    }

}