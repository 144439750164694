@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentCapsule {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background: var(--grey);
  position: relative;

  @include positioning.flex-globally;

  .icon {
    height: 40%;
    width: 40%;
    filter: var(--icon-white);
  }

  .soon {
    position: absolute;
    background: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 60%;
    font-size: 2.5vw;
    border-radius: 15px;
    bottom: 0;
    right: -5px;

    .position {
      position: relative;
      display: flex;
      align-items: center;
      background: transparent;
      width: 80%;
      height: 100%;
    }
  }

  &.chatBot, &.mails, &.clientService, &.livechat, &.medias, &.engagement, &.advertising, &.mailling, &.calendar, &.documents, &.products, &.estimate, &.contactsGrey, &.inboxGrey, &.publisherGrey, &.document {
    .icon {
      filter: var(--icon-black);
    }
  }

  &.inbox {
    background: var(--grad-inbox);
  }

  &.statistics {
    background: var(--grad-statistics);
  }

  &.blog {
    background: var(--grad-blog);
  }

  &.helpCenter {
    background: var(--grad-help-center);
  }

  &.sell {
    background: var(--grad-sell);
  }

  &.publisher {
    background: var(--grad-publisher);
  }

  &.contacts {
    background: var(--grad-contacts);
  }

  &.sedestral, &.general {
    background: var(--grad-sedestral);
  }
}