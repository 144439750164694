@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalSelectbox {
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  border: var(--grey-sur) 2px solid;
  overflow: hidden;
  width: calc(100% - 4px);
  position: relative;
  height: calc(100% - 4px);
  color: var(--black);

  @include effects.hover-alpha;
  @include texts.font("semi-bold");

  &:hover {
    border-color: transparent !important;
  }

  &.disabled {
    pointer-events: none;
    background: var(--grey-sur);
  }

  .selectIcon {
    height: 12px;
    width: 12px;
    top: calc(50% - 6px);
    pointer-events: none;
    right: 8px;
    position: absolute;
    float: right;
    filter: var(--icon-black);


    @include icons.ico("black-bottom-bold");
  }

  .selectButton {
    width: calc(100% - 10px);
    height: 100%;
    border-radius: 6px;
    padding-left: 10px;

    @include positioning.flex-vertically;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px !important;
  }

}