import {AccountBoardComponent} from "../board/AccountBoardComponent";
import {
    VisualTabComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tab/VisualTabComponent";
import * as s from "./account-navigation.scss";
import {AccountNotificationsComponent} from "../notifications/AccountNotificationsComponent";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {Resources} from "../../../../../../resources/Resources";

export class AccountNavigationComponent extends VisualTabComponent {
    constructor() {
        super();
        this.template = `
            <div class="${s.componentAccountNavigation}">
                <div class="${s.navigationContent}"></div>
                <div class="${s.navigationButtons}">
                    <div class="${s.button} ${s.board}">
                        <div class="${s.selected}"></div>
                        ${Resources.t("sentences.account.account")}
                    </div>
                    <div class="${s.button} ${s.notifications}">
                        ${Resources.t("sentences.account.notifications")}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.navigationContent);
        this.views[s.board] = () => {
            let board = new AccountBoardComponent();
            board.onPick = () => this.onBoardPick();

            return board;
        };
        this.views[s.notifications] = () => new AccountNotificationsComponent();

        super.commit();
        this.renderNotifications();
    }

    renderNotifications() {
        if (EntityService.activeNotifications.total > 0) {
            //language=HTML
            this.el(s.notifications).append(`
                <div class="${s.notifs}">
                    ${EntityService.activeNotifications.total}
                </div>
            `);
        }
    }

    onBoardPick() {

    }

}