import {INetworkComponent} from "../../network/types/INetworkComponent";
import {Network} from "../../network/Network";
import {HttpStatus} from "../../network/status/HttpStatus";
import {AccountService} from "../account/AccountService";
import {IEntityModel} from "../../models/entity/IEntityModel";
import {Models} from "../../models/Models";
import {ContactService} from "../contact/ContactService";
import {Services} from "../Services";
import {IAvatarModel} from "../../models/avatar/IAvatarModel";
import {WebSocketPanelEventName} from "../../network/socket/names/WebSocketPanelEventName";
import {EntityType} from "../../models/entity/types/EntityType";
import {IFileModel} from "../../models/file/IFileModel";
import {IPreviewModel} from "../../models/preview/IPreviewModel";
import {ISiteModel} from "../../models/site/ISiteModel";
import {IAccountModel} from "../../models/account/IAccountModel";
import {FileService} from "../file/FileService";
import {PreviewService} from "../preview/PreviewService";
import {IContactModel} from "../../models/contact/IContactModel";
import {INotificationCountModel} from "../../models/notification/count/INotificationCountModel";
import {NetworkHeaders} from "../../network/types/NetworkHeaders";
import {ITypingModel} from "../../models/entity/ITypingModel";
import {SiteService} from "../site/SiteService";
import {ProductType} from "../../models/product/ProductType";
import {config} from "../../config";
import {ProductName} from "../../models/product/ProductName";
import {
    SedestralStorage
} from "../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";
import {IOfferProductModel} from "../../models/offer/product/IOfferProductModel";
import {SedestralMemory} from "../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralMemory";

export class EntityService {

    public static typingEvents: ((entity: IEntityModel, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) => void)[] = [];
    public static activeEntity: IEntityModel = ({} as any);
    public static activeSite: ISiteModel;
    // @ts-ignore
    public static activeOfferProducts: { [K in ProductType]: IOfferProductModel } = {};
    public static activeOfferIsFailureToPay: boolean = false;
    public static activeNotifications: INotificationCountModel;
    public static cookieSessionName = `_activeToken` + config.environment;
    public static _activeWebPushTokenValue: string;

    private static _activeToken: string = "_activeToken"
    private static _saveActiveToken: string = "_saveActiveToken";
    private static _activeWebPushToken: string = "_activeWebPushToken";

    /**
     * get and set
     */

    static get activeWebPushToken(): string {
        return this._activeWebPushTokenValue;
    }

    static set activeWebPushToken(token: string) {
        this._activeWebPushTokenValue = token;
        SedestralStorage.setItem(this._activeWebPushToken, this._activeWebPushTokenValue);
    }

    static get activeToken(): string {
        return SedestralStorage.getItem(this._activeToken);
    }

    static set activeToken(token: string) {
        SedestralStorage.setItem(this._activeToken, token);
    }

    static clearActiveToken() {
        SedestralStorage.removeItem(this._activeToken);
    }

    static get saveActiveToken(): string {
        return SedestralMemory.getItem(this._saveActiveToken);
    }

    static set saveActiveToken(token: string) {
        SedestralMemory.setItem(this._saveActiveToken, token);
    }

    static clearSaveActiveToken() {
        SedestralMemory.removeItem(this._saveActiveToken);
    }

    static get activeTokenCookie(): string | null {
        return SedestralStorage.getCookie(EntityService.cookieSessionName);
    }

    static set activeTokenCookie(token: string) {
        SedestralStorage.setCookie(EntityService.cookieSessionName, token, "." + config.domainParent);
    }

    static clearTokenCookie() {
        SedestralStorage.deleteCookie(EntityService.cookieSessionName);
    }

    public static dispose(): void {
        this.typingEvents = [];
    }

    public static init(): void {
        Services.beforeInit(this);
        this._activeWebPushTokenValue = SedestralStorage.getItem(this._activeWebPushToken);
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ENTITY_UPDATE, (data) => {
            let entity: IEntityModel = data;
            if (Models.isAccountModel(entity)) {
                AccountService.store(entity as IAccountModel);
            } else if (Models.isContactModel(entity)) {
                ContactService.store(entity as IContactModel);
            }
        });


        //TODO remove typing only if typing is active
        Services.on(this, ProductType.PANEL, WebSocketPanelEventName.ENTITY_TYPING, (data: ITypingModel) => {
            // console.log(data)
            let entity = this.store(data.entity as IEntityModel, data.entityType);

            data.files = FileService.storeAll(data.files);
            data.previews = PreviewService.storeAll(data.previews);

            this.typingEvents.forEach(value => value(entity, data.content, data.files, data.previews));
        });

        if (config.product != ProductName.toString(ProductType.LIVECHAT)) {
            window["EntityService"] = this;
        }
    }

    /**
     * http
     */

    public static async updateAvatar(entityId: string, entityType: EntityType, files: File[], component?: INetworkComponent): Promise<IEntityModel> {
        let request = await Network.postFormData(ProductType.PANEL, `/entities/avatar/update`, {
            files: files,
            entityId: entityId,
            entityType: entityType
        }, component);

        if (request.status == HttpStatus.OK) {
            return this.store(request.data);
        }

        return undefined;
    }

    /**
     * get
     */

    public static getEntity(type: EntityType, id: string): IEntityModel {
        if (type == EntityType.ACCOUNT) {
            return AccountService.accounts.filter(value => value.id == id)[0];
        } else {
            return ContactService.contacts.filter(value => value.id == id)[0];
        }
    }

    public static getType(entity: IEntityModel): EntityType {
        return Models.isContactModel(entity) ? EntityType.CONTACT : EntityType.ACCOUNT;
    }

    /**
     * websocket
     */

    public static typing(conversationId: string, typing: boolean, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) {
        Network.emit(ProductType.PANEL, WebSocketPanelEventName.ENTITY_TYPING, {
            conversationId: conversationId,
            typing: typing,
            content: content,
            filesIds: files ? files.map(value => value.id) : undefined,
            previewsIds: previews ? previews.map(value => value.id) : undefined
        });
    }

    /**
     * headers
     */

    public static headersToken(headers, saveCookie: boolean = true) {
        if (headers !== undefined && headers[NetworkHeaders.SESSION_HEADER] != undefined) {
            EntityService.activeToken = headers[NetworkHeaders.SESSION_HEADER];

            if (saveCookie) {
                EntityService.activeTokenCookie = headers[NetworkHeaders.SESSION_HEADER];
            }

        }
    }

    /**
     * virtual
     */

    public static virtualGenerate(avatar: IAvatarModel): IEntityModel {
        return {
            work: "",
            avatar: avatar,
            siteId: "virtualId",
            name: "name",
            id: "virtualId",
            email: "email",
            active: true,
            avatarId: avatar.id,
            twoFactor: false,
        }
    }

    /**
     * events
     */

    public static onTyping(func: ((entity: IEntityModel, content?: string, files?: IFileModel[], previews?: IPreviewModel[]) => void), component: INetworkComponent) {
        this.typingEvents.push(func);
        component.onRemove(() => this.typingEvents.splice(this.typingEvents.indexOf(func), 1));
    }

    /**
     * store
     */

    public static storeContext(entity: IEntityModel, site: ISiteModel) {
        this.activeEntity = this.store(entity, entity.type);
        this.activeSite = SiteService.store(site);
    }

    public static storeAll(entities: IEntityModel[], type?: EntityType): IEntityModel[] {
        for (let key in entities)
            entities[key] = this.store(entities[key], type);

        return Services.storeAll(entities);
    }

    public static store(entity: IEntityModel, type?: EntityType): IEntityModel {
        if (type == undefined) {
            if (Models.isContactModel(entity))
                // @ts-ignore
            {
                entity = ContactService.store(entity as IContactModel);
            } else if (Models.isAccountModel(entity))
                // @ts-ignore
            {
                entity = AccountService.store(entity);
            }
        } else {

            if (type == EntityType.ACCOUNT) {
                // @ts-ignore
                entity = AccountService.store(entity);
            } else {
                // @ts-ignore
                entity = ContactService.store(entity);
            }
        }


        return entity;
    }

}