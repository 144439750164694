.scrollVirtual {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .scrollVirtualTrack {
    right: 0;
    top: 4px;
    bottom: 0;
    z-index: 10;
    height: calc(100% - 8px);
    width: 4px;
    cursor: pointer;
    transition: 0.1s;
    border-radius: 20px;
    position: absolute;

    &:hover {
      background: rgb(0 0 0 / 20%);
      width: 8px !important;

      .scrollVirtualBar {
        background: rgb(0 0 0 / 50%);
      }
    }
  }

  .scrollVirtualSelect {
    user-select: none;
  }

  .scrollVirtualBar {
    background: #0000006b;
    width: 100%;
    border-radius: 20px;
    right: 0;
    top: 0;
    z-index: 0;
    cursor: grab;

    position: relative;

    &:hover {
      background: rgb(0 0 0 / 60%);
    }
  }

  .scrollVirtualBar.scrollVirtualGrabbed {
    cursor: grab;
    background: rgb(0 0 0 / 50%);
  }

  .scrollVirtualGrabbed {
    cursor: grab;
    background: rgb(0 0 0 / 20%);
    width: 8px !important;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
}